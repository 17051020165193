body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.FormModal {
  .ant-form-item:last-of-type {
    text-align: center;
    margin-bottom: 0;
  }
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100vh;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right-width: 5px;
}

.ant-layout-content {
  overflow-x: auto;
}

h2 {
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}
